<template>
  <div class="page">
    <div class="c-registration-process c-registration-process_h-800">
      <div class="c-registration-process__body c-registration-process__body_top-208">
        <img src="@/assets/images/old-alpha/hello.png" alt="email-check">
        <h2 class="c-registration-process__title"><span class="c-registration-process__title_black">Hello!</span></h2>
        <p class="c-registration-process__content">Welcome to your Sparrow account. <br />Please let us guide you through the registration process.</p>
      </div>
      <div class="c-registration-process__step">
          <div class="c-registration-process__step-list">
              <div class="c-registration-process__step-block">
                  <div class="c-registration-process__step-icon">
                      <img src="@/assets/images/old-alpha/email-black.png" class="c-registration-process__step-icon-confirm" alt="Confirm Email">
                  </div>
                  <p class="c-registration-process__step-content-black">1.<span class="c-registration-process__step-content-small"><br>CONFIRM<br>EMAIL ADDRESS</span></p>
                  <div class="c-registration-process__step-button">
                      <button class="c-registration-process__small-black-button c-registration-process__mini-button" type="button" name="button"><img class="c-registration-process__black-button-icon" src="@/assets/images/old-alpha/check-button.png" alt="Done">Done</button>
                  </div>
              </div>
              <span class="c-registration-process__step-dot">&nbsp;&nbsp; . . . . . . . . . . . . . . . . .</span>
              <div class="c-registration-process__step-block">
                  <div class="c-registration-process__step-icon">
                    <img src="@/assets/images/old-alpha/identity-card.png" class="c-registration-process__step-icon-verify" alt="Verify Identity">
                  </div>
                  <p class="c-registration-process__step-content-black">2.<span class="c-registration-process__step-content-small"><br>VERIFY<br>YOUR IDENTITY</span></p>
                  <div class="c-registration-process__step-button">
                    <button @click='verifyButton()' class="c-registration-process__small-yellow-button c-registration-process__mini-button" type="button" name="button">Continue</button>
                  </div>
                  <div class="c-registration-process__step-skip">
                    <a class="c-registration-process__link" @click='redirectTo()'><span class="c-registration-process__link_underscore">Skip</span> >></a>
                  </div>
              </div>
              <span class="c-registration-process__step-dot c-registration-process__step-content-small_soft-gray">. . . . . . . . . . . . . .&nbsp;&nbsp;</span>
              <div class="c-registration-process__step-block">
                  <div class="c-registration-process__step-icon">
                      <img src="@/assets/images/old-alpha/thumb-up.png" class="c-registration-process__step-icon-set" alt="All Done">
                  </div>
                  <div>
                      <span class="c-registration-process__step-content-small c-registration-process__step-content-small_soft-gray">THAT’S IT<br>YOU’RE ALL SET!</span>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'RegistrationProcess',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    redirectTo() {
      window.location = 'dashboard/#/checker'
    },
    verifyButton() {
      this.$router.push("/verification-process").catch(() => {});
    }
  }
}
</script>
